// token details
const contracts = {
    lpStaking: {
        contract: "secret1cr4ksw99fagg7lyvjuqhhxrernkxadtlzq9m6l",
        hash: "bb36a4d78b4597996f1ee8f95748b4178d0c503ea5cb11879aea1c0bb8bbb3fe",
    },
};


export default contracts;


